import {
  AllFormInstanceSignatures,
  AssessmentScore,
  AttendanceStatusDropdown,
  FormEditorButtons,
  FormInstanceBanners,
  FormPrescenceWatcher,
  FormQuestions,
  ISPAssessmentHistory,
  ManageFormsRightBar,
  MissingAttendanceNote,
  ScheduleItemFormInstanceLinking,
  ServiceDateTimeField,
  UserSignatures,
} from "@components";
import {skipToken} from "@reduxjs/toolkit/query/react";
import {
  AttendanceStatusValues,
  clearLocalFormInstanceStore,
  Form,
  MissingAttendanceStatuses,
  setRouteReadOnly,
  useGetFormInstancesByIdQuery,
  usePatchFormInstancesByIdMutation,
  userName,
} from "@store";
import {StaffStackScreenProps} from "@types";
import {pageOnError} from "@utils";
import {Box, Button, Heading, Page, Spinner, Text} from "ferns-ui";
import React, {ReactElement, useEffect, useState} from "react";
import {Dimensions} from "react-native";
import {useDispatch} from "react-redux";

interface FormScreenProps extends StaffStackScreenProps<"ManageFormScreen"> {}

export const ManageFormScreen = ({route, navigation}: FormScreenProps): ReactElement => {
  const dispatch = useDispatch();
  const [updateFormInstance] = usePatchFormInstancesByIdMutation();
  const {data: formInstance} = useGetFormInstancesByIdQuery(
    route.params.formId ? route.params.formId : skipToken
  );

  const form = formInstance?.form as Form | undefined;
  const formUser = formInstance?.userId;
  const createdByUser = formInstance?.createdBy;
  const windowDimensions = Dimensions.get("window");

  const [dimensions, setDimensions] = useState({
    height: windowDimensions.height,
    width: windowDimensions.width,
  });

  // Set the read only state for the form instance
  useEffect(() => {
    dispatch(setRouteReadOnly(Boolean(route.params?.readOnly)));
  }, [dispatch, route.params?.readOnly]);

  // If the window dimensions change, update our state
  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", ({window}) => {
      setDimensions({height: window.height, width: window.width});
    });
    return (): void => subscription?.remove();
  }, []);

  // Set the header title to the form name and the user's name
  useEffect(() => {
    const loadForm = async (): Promise<void> => {
      if (formInstance) {
        navigation.setOptions({
          headerTitle: `${(formInstance.form as Form).name} - ${userName(formUser)}`,
        });
      }
    };
    void loadForm();
  }, [formInstance, formUser, navigation]);

  // Dispatch clearFormInstanceData when the component is unmounted
  useEffect(() => {
    return (): void => {
      if (formInstance?._id) {
        dispatch(clearLocalFormInstanceStore());
      }
    };
  }, [dispatch, formInstance?._id]);

  if (!formInstance) {
    return (
      <Box alignItems="center" justifyContent="center" paddingY={4} width="100%">
        <Spinner />
      </Box>
    );
  }

  return (
    <Page color="neutralLight" maxWidth="100%" navigation={navigation} onError={pageOnError}>
      {Boolean(formInstance?._id) && <FormPrescenceWatcher formInstanceId={formInstance?._id} />}
      <Box direction="row" flex="grow" width="100%">
        {/* Width should only be 70% if the screen is wide enough to display the staff side bar */}
        <Box width={dimensions?.width > 1100 ? "70%" : "100%"}>
          {/* Take 140 pixels out due to padding, header, and bottom tab bar so we don't end up with a third scroll bar on the page */}
          <Box maxHeight={dimensions?.height - 140} scroll width="100%">
            <Box color="neutralLight" marginRight={4} rounding="md">
              <Box color="neutralLight" rounding="md" scroll>
                <Box alignItems="center" direction="row" justifyContent="between" width="100%">
                  <Heading>{form?.name}</Heading>
                </Box>
                {Boolean(form?.description) && (
                  <Box paddingY={2}>
                    <Text>{form?.description}</Text>
                  </Box>
                )}
                <Box paddingY={2}>
                  <Text>Created By: {createdByUser?.name}</Text>
                </Box>
                {Boolean(
                  form?.allowUserCompletion &&
                    !["Sent To User", "Completed", "Signed", "Requires Supervisor Action"].includes(
                      formInstance.status
                    )
                ) && (
                  <Box alignItems="start" paddingY={2}>
                    <Button
                      confirmationText="Are you sure you want to send this form to the user? This will make it visible to them."
                      text="Send to User"
                      withConfirmation
                      onClick={async () => {
                        await updateFormInstance({
                          id: formInstance._id,
                          body: {
                            status: "Sent To User",
                          },
                        });
                      }}
                    />
                  </Box>
                )}
                <AllFormInstanceSignatures formInstanceId={formInstance?._id} />
                <FormInstanceBanners formInstanceId={formInstance?._id} />

                {/* Support deprecated allowScheduleItemLinking */}
                {form?.allowScheduleItemLinking && !formInstance.isAutoCreatedByScheduleItem ? (
                  <ScheduleItemFormInstanceLinking formInstanceId={formInstance?._id} />
                ) : (
                  <>
                    <ServiceDateTimeField formInstanceId={formInstance._id} />
                    <AttendanceStatusDropdown formInstanceId={formInstance._id} />
                  </>
                )}

                {/* Auto Created FormInstances have specific fields for missing attendance */}
                {formInstance?.isAutoCreatedByScheduleItem &&
                MissingAttendanceStatuses.includes(
                  (formInstance?.attendanceStatus as AttendanceStatusValues) ?? ""
                ) ? (
                  <MissingAttendanceNote formInstanceId={formInstance._id} />
                ) : (
                  <FormQuestions formInstanceId={formInstance._id} />
                )}

                <UserSignatures formInstanceId={formInstance?._id} />
                {formUser?._id && form?.internalKey === "isp" && (
                  <ISPAssessmentHistory userId={formUser?._id} />
                )}
                <Box color="base" marginBottom={2} marginTop={2} rounding="md">
                  <AssessmentScore formInstanceId={formInstance?._id} />
                </Box>
                <FormEditorButtons formInstanceId={formInstance._id} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box paddingX={3} width="30%">
          {Boolean(dimensions?.width && dimensions.width > 1100) && (
            /**
             * Take 140 pixels out due to padding, header,
             * and bottom tab bar so we don't end up with a third scroll bar on the page
             */
            <Box maxHeight={dimensions?.height - 140} scroll width="100%">
              <ManageFormsRightBar userId={formUser?._id} />
            </Box>
          )}
        </Box>
      </Box>
    </Page>
  );
};
