// This should match the ScheduleItemType enum.
export type AppointmentType =
  | "Eligibility Interview"
  | "Clinical Intake"
  | "Guide Clinical Intake"
  | "Therapy Clinical Intake"
  | "Therapy - Patient Session"
  | "Therapy - Caregiver Session"
  | "Therapy Intake"
  | "Psychiatry Intake"
  | "In Home Onboarding Visit"
  | "Therapy"
  | "Psychiatry"
  | "Member Guide"
  | "Patient Guide"
  | "Family Guide"
  | "In Home Guide Visit"
  | "Travel"
  | "Individualized Service Plan"
  | "Meet and Greet"
  | "Other";

export type IntakeAppointmentType =
  | "Therapy Intake"
  | "Psychiatry Intake"
  | "In Home Onboarding Visit"
  | "Guide Clinical Intake"
  | "Therapy Clinical Intake";

// Copied from ferns-ui. We can't import on the backend.
type FieldOption = {
  label: string;
  key?: string;
  value: string;
};

// Copied from the rest of the app since we can't import it.
type StaffRole =
  | "Therapist"
  | "Psychiatrist"
  | "PatientGuide"
  | "FamilyGuide"
  | "CareAdvocate"
  | "EnrollmentCoordinator";

export const SCHEDULE_ITEM_DEFAULT_USER_NOTE =
  "Message your guide in app if you know 2 days in advance that you cannot make your appointment.";

export type NotificationsState = {minutesBefore: number; sendAsPush: boolean; sendAsSms: boolean}[];

export enum RecurringType {
  NoRepeat = "NoRepeat",
  Weekly = "Weekly",
}

export const RecurringTypeOptions: FieldOption[] = [
  {label: "Does not repeat", value: RecurringType.NoRepeat},
  {label: "Repeats Weekly", value: RecurringType.Weekly},
];

export type AppointmentConfig = {
  // Displayed in in Google Calendar, schedule screen, etc
  title: string;
  reminders: {minutesBefore: number; sendAsPush: boolean; sendAsSms: boolean}[];
  duration: number;
  userNotes?: () => string;
  staffRoles?: StaffRole[];
  // If true, will include a video link in the meeting details and sms reminders.
  video?: boolean;
  // If true and the patient is a minor, the patient's guardian will be invited to the
  // meeting.
  psychiatry?: boolean;
  // Intake meetings can be scheduled with the new appointment screen
  intakeScheduling?: boolean;
  // If true, will show travel time in the appointment scheduler
  travelTime?: boolean;
  documentationTime?: number;
  // Group is used to color the appointment in the schedule screen.
  group: "Therapy" | "Psychiatry" | "Guide" | "Enrollment" | "Other";
  // Whether to create a feedback survey for the meeting
  feedbackSurvey?: boolean;
  engagementType?: "Clinical" | "NonClinical";
  associatedFormConfig?: {
    internalKey: string; // The internal key of the form associated with this appointment type for FI creation
    userTypeForUserId: "Patient" | "FamilyMember"; // Used to verify the FI is associated with the correct user
    staffRoleForCreatedById: StaffRole; // Used to verify the FI is associated with the correct staff
  };
  // Intake meetings can be scheduled with the new appointment screen as well as the old screen
  intake?: boolean;
  // Used to filter appt type options in create schedule item dropdown
  deprecated?: boolean;
};

export const APPOINTMENT_CONFIG: {
  [K in AppointmentType]?: AppointmentConfig;
} = {
  "Eligibility Interview": {
    title: "Pre-Enrollment Consultation",
    reminders: [
      {minutesBefore: 5, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 15, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 60, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 60,
    staffRoles: [],
    video: true,
    group: "Therapy",
    feedbackSurvey: true,
    engagementType: "NonClinical",
    associatedFormConfig: {
      internalKey: "collaborative-intake-e-v2",
      userTypeForUserId: "Patient",
      staffRoleForCreatedById: "EnrollmentCoordinator",
    },
  },
  // TODO: deprecate Clinical Intake once Scheduling v2 rolls out in favor or Guide Clinical
  // Intake and Therapy Clinical Intake
  "Clinical Intake": {
    title: "Clinical Intake",
    reminders: [
      {minutesBefore: 5, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 15, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 60, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 120,
    staffRoles: ["Therapist", "PatientGuide"],
    video: true,
    group: "Therapy",
    feedbackSurvey: true,
    engagementType: "Clinical",
    intake: true,
  },
  "Guide Clinical Intake": {
    title: "Guide Clinical Intake",
    reminders: [
      {minutesBefore: 5, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 15, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 60, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 60,
    staffRoles: ["PatientGuide", "FamilyGuide"],
    video: true,
    intakeScheduling: true,
    group: "Enrollment",
    feedbackSurvey: true,
    engagementType: "NonClinical",
    intake: true,
  },
  "Therapy Clinical Intake": {
    title: "Therapy Clinical Intake",
    // No reminders, because this is directly after the Guide Clinical Intake.
    reminders: [],
    duration: 70,
    staffRoles: ["Therapist"],
    video: true,
    intakeScheduling: true,
    group: "Enrollment",
    feedbackSurvey: true,
    engagementType: "Clinical",
    associatedFormConfig: {
      internalKey: "collaborative-intake-gtp-v2",
      userTypeForUserId: "Patient",
      staffRoleForCreatedById: "Therapist",
    },
    intake: true,
  },
  // Deprecated
  "Therapy Intake": {
    title: "Therapy Intake",
    reminders: [
      {minutesBefore: 30, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 120,
    staffRoles: ["Therapist"],
    video: true,
    intakeScheduling: true,
    group: "Therapy",
    feedbackSurvey: true,
    engagementType: "Clinical",
    intake: true,
  },
  "Psychiatry Intake": {
    title: "Psychiatry Intake",
    reminders: [
      {minutesBefore: 30, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 80,
    staffRoles: ["Psychiatrist"],
    psychiatry: true,
    video: true,
    group: "Psychiatry",
    intakeScheduling: true,
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "Clinical",
    intake: true,
  },
  "In Home Onboarding Visit": {
    title: "Guide In-Person Visit",
    reminders: [
      {minutesBefore: 30, sendAsPush: false, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: false, sendAsSms: true},
    ],
    duration: 60,
    staffRoles: ["PatientGuide"],
    group: "Guide",
    intakeScheduling: true,
    travelTime: true,
    feedbackSurvey: true,
    engagementType: "NonClinical",
    intake: true,
  },
  // Follow ups
  Therapy: {
    title: "Therapy Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 40,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Therapist"],
    video: true,
    group: "Therapy",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "Clinical",
    deprecated: true,
  },
  "Therapy - Patient Session": {
    title: "Therapy Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 40,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Therapist"],
    video: true,
    group: "Therapy",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "Clinical",
    associatedFormConfig: {
      internalKey: "therapy-progress",
      userTypeForUserId: "Patient",
      staffRoleForCreatedById: "Therapist",
    },
  },
  "Therapy - Caregiver Session": {
    title: "Therapy Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 40,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Therapist"],
    video: true,
    group: "Therapy",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "Clinical",
  },
  Psychiatry: {
    title: "Psychiatry Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 25,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Psychiatrist"],
    psychiatry: true,
    group: "Psychiatry",
    video: true,
    documentationTime: 5,
    feedbackSurvey: true,
    engagementType: "Clinical",
    associatedFormConfig: {
      internalKey: "psychiatry-progress",
      userTypeForUserId: "Patient",
      staffRoleForCreatedById: "Psychiatrist",
    },
  },
  // Deprecated
  "Member Guide": {
    title: "Guide Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 20,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["PatientGuide"],
    video: true,
    group: "Guide",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "NonClinical",
    deprecated: true,
  },
  "Patient Guide": {
    title: "Guide Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 20,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["PatientGuide"],
    video: true,
    group: "Guide",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  "Family Guide": {
    title: "Guide Follow-up",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 20,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["FamilyGuide"],
    video: true,
    group: "Guide",
    documentationTime: 10,
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  "In Home Guide Visit": {
    title: "In Person Guide Visit",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 60,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["PatientGuide"],
    travelTime: true,
    group: "Guide",
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  Travel: {
    title: "Travel",
    reminders: [],
    duration: 50,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    documentationTime: 10,
    group: "Other",
  },
  "Individualized Service Plan": {
    title: "Individualized Service Plan Meeting",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 30,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["Therapist"],
    group: "Therapy",
    video: true,
    engagementType: "Clinical",
  },
  "Meet and Greet": {
    title: "Meet and Greet",
    reminders: [
      {minutesBefore: 30, sendAsPush: true, sendAsSms: true},
      {minutesBefore: 1440, sendAsPush: true, sendAsSms: true},
    ],
    duration: 30,
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    staffRoles: ["PatientGuide"],
    video: true,
    group: "Guide",
    feedbackSurvey: true,
    engagementType: "NonClinical",
  },
  Other: {
    title: "",
    reminders: [{minutesBefore: 30, sendAsPush: true, sendAsSms: true}],
    duration: 50,
    group: "Other",
    userNotes: (): string => SCHEDULE_ITEM_DEFAULT_USER_NOTE,
    documentationTime: 10,
    engagementType: "NonClinical",
  },
};

export const INTAKE_KEYS = Object.keys(APPOINTMENT_CONFIG).filter(
  (key) => APPOINTMENT_CONFIG[key as AppointmentType]?.intake === true
) as AppointmentType[];

// Filter out deprecated appt types from create schedule item dropdown
export const DEFAULT_APPT_OPTIONS = Object.keys(APPOINTMENT_CONFIG)
  .filter((key) => !APPOINTMENT_CONFIG[key as keyof typeof APPOINTMENT_CONFIG]?.deprecated === true)
  .map((key) => ({
    label: key === "Eligibility Interview" ? "Pre-Enrollment Consultation" : key,
    value: key,
  }));

export const VIDEO_MEETINGS = Object.keys(APPOINTMENT_CONFIG).filter(
  (key): key is keyof typeof APPOINTMENT_CONFIG => {
    const {video} = APPOINTMENT_CONFIG[key as keyof typeof APPOINTMENT_CONFIG] ?? {};
    return Boolean(video);
  }
);

export const DEFAULT_TITLES = Object.values(APPOINTMENT_CONFIG).map((v) => v.title);

export const NotificationOptions: Map<string, number> = new Map([
  ["5 minutes before", 5],
  ["15 minutes before", 15],
  ["30 minutes before", 30],
  ["1 hour before", 60],
  ["1 day before", 1440],
  ["2 days before", 2880],
  ["3 days before", 4320],
  ["4 days before", 5760],
  ["5 days before", 7200],
  ["6 days before", 8640],
  ["7 days before", 10080],
]);
