import {useReadProfile} from "@hooks";
import {skipToken} from "@reduxjs/toolkit/query";
import {
  Form,
  useFormInstanceIsReadOnly,
  useGetFormInstancesQuery,
  useLocalFormInstance,
  usePatchFormInstancesByIdMutation,
  useSentryAndToast,
} from "@store";
import {Box, Button, humanDateAndTime, Text} from "ferns-ui";
import React, {FC, useMemo} from "react";

import {FormQuestionComponent} from "../formInstanceQuestions";

interface FormQuestionProps {
  formInstanceId: string;
}

export const FormQuestions: FC<FormQuestionProps> = ({formInstanceId}) => {
  const profile = useReadProfile();
  const formInstance = useLocalFormInstance(formInstanceId);
  const readOnly = useFormInstanceIsReadOnly(formInstanceId);
  const sentryAndToast = useSentryAndToast();
  const [updateFormInstance] = usePatchFormInstancesByIdMutation();
  const {data: finishedInstances} = useGetFormInstancesQuery(
    formInstance
      ? {
          userId: formInstance.userId?._id, // Exact match for userId
          formId: formInstance.formId, // Exact match for formId
          $and: [
            {
              $or: [
                {attendanceStatus: "Attended"},
                // also include FIs that don't have an attendance status
                // we want to include manually made FIs for backwards compatibility
                // before autoCreated forms were introduced
                // TODO: remove null check from form instance query when autoCreated has been
                // implemented for all applicable forms in roadmap and we're sure all current FI
                // have an attendance status (about 1 weeks after the last form has been updated)
                {attendanceStatus: null},
              ],
            },
            {
              $or: [{status: "Signed"}, {status: "Completed"}],
            },
          ],
        }
      : skipToken
  );

  const form = formInstance?.form as Form | undefined;
  const answers = formInstance?.answers;
  const formQuestionsWithoutHeadings = form?.questions?.filter((q) => q.type !== "Heading");

  const userHasCompletedFiOfSameType = useMemo(() => {
    if (
      finishedInstances?.data?.length === 1 &&
      finishedInstances?.data[0]?._id === formInstanceId
    ) {
      return false;
    }

    if (
      finishedInstances?.data?.length &&
      finishedInstances.data.some((fi) => {
        const fiForm = fi?.form as Form | undefined;
        return fiForm?.internalKey === form?.internalKey;
      })
    ) {
      return true;
    }

    return false;
  }, [finishedInstances?.data, formInstanceId, form?.internalKey]);

  const duplicateAnswerCheck = useMemo(() => {
    if (!formInstance || !answers) {
      return false;
    }
    const answerMap = new Map<string, number>();
    for (const answer of answers) {
      const count = answerMap.get(answer.questionId) || 0;
      answerMap.set(answer.questionId, count + 1);
    }

    let hasDuplicates = false;
    answerMap.forEach((count, questionId) => {
      if (count > 1) {
        hasDuplicates = true;
        sentryAndToast(
          `Multiple answers found for question ${questionId} and form instance ${formInstance?._id}. Please contact support.`
        );
      }
    });

    return hasDuplicates;
  }, [answers, formInstance, sentryAndToast]);

  if (duplicateAnswerCheck) {
    console.warn("Duplicate answers found");
  }

  if (!formInstance || !form || !answers || !profile) {
    return null;
  }

  return (
    <Box paddingY={2}>
      {formInstance?.isAutoCreatedByScheduleItem && userHasCompletedFiOfSameType && !readOnly && (
        <Box marginTop={2} maxWidth={400}>
          <Button
            confirmationText={`Copying answers from the most recent "${form.name}". \n\nIt will OVERWRITE any existing answers in this ${form.type} and CANNOT be reversed. \n\nAre you sure you want to proceed?`}
            iconName="copy"
            text={`Autofill Answers from Last Completed ${form.type}`}
            variant="secondary"
            withConfirmation
            onClick={async (): Promise<void> => {
              await updateFormInstance({
                id: formInstanceId,
                body: {
                  copyMostRecentAnswers: true,
                } as any,
              })
                .unwrap()
                .catch((error) => {
                  sentryAndToast(`Error copying answers from last signed ${form.name}.`, error);
                });
            }}
          />
        </Box>
      )}
      {form.questions?.map((q) => {
        if (!q._id) {
          console.error("Form question has no ID");
          return null;
        }
        return (
          <FormQuestionComponent
            key={q._id}
            formInstanceId={formInstance._id}
            index={formQuestionsWithoutHeadings?.findIndex((f) => f._id === q._id) ?? 0}
            questionId={q._id}
            userId={formInstance.userId._id}
          />
        );
      })}
      <Box marginBottom={4}>
        <Text>Last saved: {humanDateAndTime(formInstance.updated)}</Text>
      </Box>
    </Box>
  );
};
